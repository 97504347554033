import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Tabs, Tab } from "@mui/material";
import PassengerFlightFilter from "../../../features/shipmentTracks/passengerFlight/passengerFlightFilter";
import OnTimeFlightsList from "../../../features/shipmentTracks/passengerFlight/onTimeFlightsList";
import DelayedFlightsList from "../../../features/shipmentTracks/passengerFlight/delayedFlightList";
import CancelledFlightsList from "../../../features/shipmentTracks/passengerFlight/cancelledFlightList";
import { useGridApiRef } from "@mui/x-data-grid";
import { useLazyGetPassengerFlightQuery } from "../../../services/shipmentTrack";
import { showError } from "../../../constants/toasts";
import { NewOrder } from "../../../types/newOrder";
import Loader from "../../../constants/Loader";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PassengerFlight = () => {
  const navigate = useNavigate();
  const gridApi = useGridApiRef();
  const [selectedRow, setSelectedRow] = useState<any>({
    row: [],
    column: [],
  });
  const [valueTabs, setValueTabs] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [newOrders, setNewOrders] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalDataCount, setTotalDataCount] = useState({
    type1: 0,
    type2: 0,
    type3: 0,
  });
  const [getAllData] = useLazyGetPassengerFlightQuery();

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValueTabs(newValue);
  };
  const setColumnVisibilityModelValue = (value: number) => {
    if (value == 1)
      setColumnVisibilityModel1({
        traveler: true,
        travelerMobile: true,
        travelerEmail: true,
        status: true,
        departureDate: true,
        currentCity: true,
        // delayDepartureDate: true,
        shipments: true,
        destinationCity: true,
        destinationCity2: true,
        destinationCity3: true,
        action: true,
        wayOfTravel: true,
        availableWeight: true,
        dateOfAcceptance: true,
      });
    else
      setColumnVisibilityModel1({
        traveler: true,
        travelerMobile: true,
        travelerEmail: true,
        status: true,
        departureDate: true,
        currentCity: true,
        // delayDepartureDate: true,
        shipments: true,
        destinationCity: true,
        destinationCity2: true,
        destinationCity3: true,
        comment: true,
        action: true,
        wayOfTravel: true,
        availableWeight: true,
        dateOfAcceptance: true,
        flag: true,
      });
    setSearchTerm("");
  };
  const [columnVisibilityModel1, setColumnVisibilityModel1] = useState<any>({
    traveler: true,
    travelerMobile: true,
    travelerEmail: true,
    status: true,
    departureDate: true,
    currentCity: true,
    // delayDepartureDate: true,
    shipments: true,
    destinationCity: true,
    destinationCity2: true,
    destinationCity3: true,
    comment: true,
    action: true,
    wayOfTravel: true,
    availableWeight: true,
    dateOfAcceptance: true,
    flag: true,
  });
  useEffect(() => {
    setColumnVisibilityModelValue(valueTabs + 1);
  }, [valueTabs]);
  const getAllDataList = async () => {
    try {
      setIsLoading(true);
      const response = await getAllData({
        page: 1,
        type: valueTabs + 1,
        query: "",
      }).unwrap();
      if (response?.statusCode == 200) {
        setNewOrders(response?.data?.data);

        //
        // if (valueTabs + 1 == 1)
        //   setTotalDataCount((prevData) => ({
        //     ...prevData,
        //     type1: response?.data?.count,
        //   }));
        // else if (valueTabs + 1 == 2)
        //   setTotalDataCount((prevData) => ({
        //     ...prevData,
        //     type2: response?.data?.count,
        //   }));
        // else
        //   setTotalDataCount((prevData) => ({
        //     ...prevData,
        //     type3: response?.data?.count,
        //   }));
        getAllDataListTotalCount(1);
        getAllDataListTotalCount(2);
        getAllDataListTotalCount(3);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };
  const getAllDataListTotalCount = async (type: number) => {
    try {
      const response = await getAllData({
        page: 0,
        type: type,
        query: "",
      }).unwrap();
      if (response?.statusCode == 200) {
        if (type == 1) {
          setTotalDataCount((prevData: any) => {
            return { ...prevData, type1: response?.data?.count };
          });
        } else if (type == 2) {
          setTotalDataCount((prevData: any) => {
            return { ...prevData, type2: response?.data?.count };
          });
        } else if (type == 3) {
          setTotalDataCount((prevData: any) => {
            return { ...prevData, type3: response?.data?.count };
          });
        }
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  useEffect(() => {
    getAllDataList();
    getAllDataListTotalCount(1);
    getAllDataListTotalCount(2);
    getAllDataListTotalCount(3);
  }, [valueTabs]);

  const getFilteredData = (data: any) => {
    return data.filter((data: NewOrder) =>
      `${data?.traveller?.firstName} ${data?.traveller?.lastName}`
        ?.toLocaleLowerCase()
        .includes(searchTerm?.toLocaleLowerCase().toString())
    );
  };

  return (
    <>
      <Loader isLoad={isLoading} />
      <div className="main_title">
        <h1>Passenger Flight</h1>
        <p>
          <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
          Shipment Track - Passenger flight
        </p>
      </div>

      <div className="table_header">
        <div className="left_s">
          <Box className="custom_tabs1" sx={{ width: "100%" }}>
            <Tabs
              value={valueTabs}
              onChange={handleChangeTabs}
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab
                label={"On Time Flights (" + totalDataCount?.type1 + ")"}
                {...a11yProps(0)}
              />
              <Tab
                label={"Delay Flights (" + totalDataCount?.type2 + ")"}
                {...a11yProps(1)}
              />
              <Tab
                label={"Cancel Flights (" + totalDataCount?.type3 + ")"}
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
        </div>
        <div className="right_s">
          <PassengerFlightFilter
            selectedRow={selectedRow}
            gridApi={gridApi}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            columnVisibilityModel={columnVisibilityModel1}
            setColumnVisibilityModel={setColumnVisibilityModel1}
          />
        </div>
      </div>

      <div className="cards">
        <CustomTabPanel value={valueTabs} index={0}>
          <OnTimeFlightsList
            getDataList={getAllDataList}
            setSelectedRow={setSelectedRow}
            gridApi={gridApi}
            newOrders={getFilteredData(newOrders)}
            columnVisibilityModel={columnVisibilityModel1}
          />
        </CustomTabPanel>
        <CustomTabPanel value={valueTabs} index={1}>
          <DelayedFlightsList
            getDataList={getAllDataList}
            setSelectedRow={setSelectedRow}
            gridApi={gridApi}
            newOrders={getFilteredData(newOrders)}
            columnVisibilityModel={columnVisibilityModel1}
          />
        </CustomTabPanel>
        <CustomTabPanel value={valueTabs} index={2}>
          <CancelledFlightsList
            getDataList={getAllDataList}
            setSelectedRow={setSelectedRow}
            gridApi={gridApi}
            newOrders={getFilteredData(newOrders)}
            columnVisibilityModel={columnVisibilityModel1}
          />
        </CustomTabPanel>
      </div>
    </>
  );
};

export default PassengerFlight;
